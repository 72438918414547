import React from "react";

function PodcastCard(props){
    console.log('url is: ' + props.url);
    const url = props.url;

    return(
        <div className="post-card  custom-center">
            <h3 className="w3-left-align sizeable-text">{props.title}
                <a className='w3-right sizeable-text'> {props.postDate}</a>
            </h3>
        <center>
                <iframe className="podcast-player" src={props.url}
                        title="Podcast player" frameBorder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen></iframe>
        </center>
            <h5 className="w3-left-align"> {props.message}</h5>

            <div className='tags'>
                {props.tagArray.map((tag) => <a >{tag}{" "} </a>)}
            </div>



        </div>
    );
}
export default PodcastCard;