import React from 'react';
import {Link} from 'react-router-dom';
import armorImg from '../resources/logocut.png'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBars } from '@fortawesome/free-solid-svg-icons'

function NavBar(){
    return(
        <nav>
            {/*<div className="w3-container w3-teal">*/}

                <div className="">



                </div>
                <div className="w3-block w3-container w3-hide-medium w3-hide-small custom-center">
                    <img src={armorImg} className="w3-left top-logo"/>
                    <div className = "w3-right w3-center w3-cursive w3-xlarge w3-padding">
                        <a><Link to="/" >Home</Link> |{" "}</a>

                        <a><Link to="/Search">Search</Link> |{" "}</a>
                        <a><Link to="/Questions">Submit A Question</Link></a>
                    </div>

                </div>

                <div className="w3-block w3-border-cyan  w3-dropdown-click w3-hide-large w3-xxlarge">
                    <img src={armorImg} className="w3-left mini-logo"/>
                   <div className="w3-right-align " onClick={()=>toggleMenu()}>
                       <FontAwesomeIcon icon={faBars}/>
                   </div>

                    <div id="nav" className="shift-left w3-right-align w3-dropdown-content w3-border w3-hide w3-hide-large w3-xlarge" >
                        <ul className="form-list w3-margin-right">
                            <li>
                                <Link to='/' className="no-underline" onClick={()=>toggleMenu()}>Home</Link>
                            </li>
                            <li>
                                <Link to='/Search' className="no-underline" onClick={()=>toggleMenu()}>Search</Link>
                            </li>
                            <li>
                                <Link to='/Questions' className="no-underline" onClick={()=>toggleMenu()}>Submit A Question</Link>
                            </li>
                        </ul>




                    </div>
                </div>

        </nav>
    );  
}

function toggleMenu() {
    console.log('opening menu button');
    var x = document.getElementById("nav");
    if (x.className.indexOf("w3-show") === -1) {
        x.className += " w3-show";
    } else {
        x.className = x.className.replace(" w3-show", "");
    }
}

export default NavBar;