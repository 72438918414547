import React, {useEffect} from 'react';
import NavBar from './NavBar.js';
import Footer from './Footer'
import InstagramEmbed from 'react-instagram-embed';
import PostCard from "./PostCard";


function HomeFeed(){
    const [posts, setPosts] = React.useState([]);
    const [isLoading, setIsLoading] = React.useState(false)

    const fetchPosts = async ()=>{
        setIsLoading(true)
        const response = await fetch('https://iekekunqsg.execute-api.us-east-1.amazonaws.com/dev/mostRecent');
        const data = await response.json();
        // console.log('data is: ' + JSON.stringify(data));
        setIsLoading(false);
        setPosts(data.Items);
    }

    useEffect(()=>{
        fetchPosts()
    }, [])

    return(
        <div className="backgroundTest">
            <div className="w3-block">
                <NavBar/>
            </div>


            <div className="w3-block">
                <div >
                    {isLoading && <div><h2 className="w3-center w3-cursive w3-xlarge">Gathering the most recent posts...</h2><div className="loading"/></div> }
                    {posts.length > 0 && (
                        // <ul className='form-list'>
                        <div >
                            {posts.map(post => (
                                <PostCard postData={post}/>
                            ))}
                        {/*// </ul>*/}
                        </div>
                    )}
                    {/*/!*need this component so children rendering of the images will work properly from instagram*!/*/}
                    <InstagramEmbed/>

                </div>

            </div>
        <Footer/>
        </div>
    );
}
export default HomeFeed;