import React from 'react';
import NavBar from './NavBar.js';
import Footer from './Footer';
import { useFilePicker } from 'use-file-picker';
import crypto from 'crypto';

function AddPost(){
    const [nameErrorMsg, nameErrorSetter] = React.useState('');
    const [emailErrorMsg, emailErrorSetter] = React.useState('');
    const [subjectErrorMsg, subjectErrorSetter] = React.useState('');
    const [messageErrorMsg, messageErrorSetter] = React.useState('');
    const [thankMsg, thankSetter] = React.useState('');
    const [selectedOption, optionSetter] = React.useState('');
    const [openFileSelector, { filesContent }] = useFilePicker({
        multiple:false,
    });

    function nameChange(){
        nameErrorSetter('')
    }
    function emailChange(){
        emailErrorSetter('')
    }
    function subjectChange(){
        subjectErrorSetter('')
    }
    function messageChange(){
        messageErrorSetter('')
    }

    async function handleSubmit(event) {
        event.preventDefault();
        const time = Date.now();
        const timeKey = await encryptTimeWithKey(time, JSON.stringify(filesContent[0].content).replace('\\n',''));

        postData(event, timeKey);

    }

    function onValueChange(event) {
        optionSetter(event.target.value);
    }

    function encryptTimeWithKey(timestamp, secretKey) {

        let iv = crypto.randomBytes(12);
        let key = secretKey.slice(1);
        key = key.slice(0,key.length-1);

        let keyBuff = Buffer.from(key, "utf-8");
        let cipher = crypto.createCipheriv('aes-128-gcm', keyBuff, iv);

        const encrypted = Buffer.concat([iv, cipher.update(timestamp.toString()), cipher.final(), cipher.getAuthTag()]);


        return encrypted.toString('base64');
    }

    function postData(event, timekey){
        let canSend = true;
        // if(event.target.elements.title.value === ''){
        //     nameErrorSetter('no title found!');
        //     canSend = false;
        // }
        // if(event.target.elements.message.value === ''){
        //     emailErrorSetter('no description found!');
        //     canSend = false;
        // }
        // if(event.target.elements.tags.value === ''){
        //     subjectErrorSetter('no tags found!');
        //     canSend = false;
        // }
        // if(event.target.elements.message.value === ''){
        //     messageErrorSetter('no message found!');
        //     canSend = false;
        // }
        if(canSend){
console.log('mediaType: ' + event.target.elements.mediaType.value);
            const postInfo = {
                title: event.target.elements.title.value,
                type: event.target.elements.mediaType.value,
                message: event.target.elements.message.value,
                tags: event.target.elements.tags.value,
                url: event.target.elements.url.value,
                postKey: timekey
            };
            window
                .fetch('https://iekekunqsg.execute-api.us-east-1.amazonaws.com/dev/saveArmorPost',
                    {
                        method: 'POST',
                        headers: {
                            'content-type': 'application/json',
                        },
                        body: JSON.stringify(postInfo),
                    })
            thankSetter('Post Saved!');
            event.target.elements.title.value = '';
            event.target.elements.message.value = '';
            event.target.elements.tags.value ='';
            event.target.elements.url.value ='';
        }
    }

    return(
        <div>
            <NavBar/>
            <div className="content-from-top " >
                <div className="w3-container">
                    <div className="w3-center w3-padding-large">
                        <button onClick={() => openFileSelector()}>Select key </button>
                            {filesContent.map((file) => (
                            <div key={file.name}>{file.name}</div>

                        ))}
                        <form onSubmit={handleSubmit}>
                            <ul className="form-list">
                                <li>
                                    <div className="radio">
                                        <label>
                                            <input
                                                type="radio"
                                                name="mediaType"
                                                value="Youtube"
                                                checked={selectedOption === "Youtube"}
                                                onChange={onValueChange}
                                            />
                                            Youtube
                                        </label>
                                    </div>
                                    <div className="radio">
                                        <label>
                                            <input
                                                type="radio"
                                                name="mediaType"
                                                value="Instagram"
                                                checked={selectedOption === "Instagram"}
                                                onChange={onValueChange}
                                            />
                                            Instagram
                                        </label>
                                    </div>
                                    <div className="radio">
                                        <label>
                                            <input
                                                type="radio"
                                                name="mediaType"
                                                value="Podcast"
                                                checked={selectedOption === "Podcast"}
                                                onChange={onValueChange}
                                            />
                                            Podcast
                                        </label>
                                    </div>
                                    <div className="radio">
                                        <label>
                                            <input
                                                type="radio"
                                                name="mediaType"
                                                value="Article"
                                                checked={selectedOption === "Article"}
                                                onChange={onValueChange}
                                            />
                                            Article
                                        </label>
                                    </div>
                                </li>
                                <li><label className="form-label" htmlFor='nameInput'>Post Title:</label></li>
                                <li><input className="long-input" id='nameInput' type="text" name="title" onChange={nameChange}/></li>
                                <li><div className="errorMsg">{nameErrorMsg}</div></li>
                                <li><label className="form-label" htmlFor='emailInput'>Post/Message text:</label></li>
                                <li><textarea className="msg-input" id='messageInput' type="text" name="message" onChange={messageChange} /></li>
                                <li><div className="errorMsg">{emailErrorMsg}</div></li>
                                <li><label className="form-label" htmlFor='subjectInput'>Tags - comma separated list:</label></li>
                                <li><input className="long-input" id='subjectInput' type="text" name="tags" onChange={subjectChange} /></li>
                                <li><div className="errorMsg">{subjectErrorMsg}</div></li>
                                <li><label className="form-label" htmlFor='messageInput'>link to site (if any):</label></li>
                                <li><input className="long-input" id='emailInput' type="text" name="url" onChange={emailChange} /></li>
                                <li><div className="errorMsg">{messageErrorMsg}</div></li>
                            </ul>
                            <button type="submit" >Submit</button>
                        </form>
                        <div className="thankmsg">{thankMsg}</div>
                    </div>

                </div>
            </div>

            <Footer/>
        </div>

    );
}
export default AddPost;