import React from 'react';
import PodcastCard from "./PodcastCard";
import VideoCard from "./VideoCard"
import InstagramCard from "./InstagramCard"
import ArticleCard from "./ArticleCard"


function PostCard(props){
    let tagArry;
    if(props.postData.tags){
        tagArry= props.postData.tags.replace(" ", "").split(',');
    } 
    const date = new Date(parseInt(props.postData.timecreated, 10) ).toDateString().split(' ');
    const displayDate = date[1] + ' ' + date[2] + ' ' + date[3];

    if(props.postData.type === 'Youtube'){
        return(<VideoCard title={props.postData.title}
                          url={props.postData.uri}
                          message={props.postData.message}
                          postDate={displayDate}
                          tagArray={tagArry}/>)
    }
    else if(props.postData.type === 'Instagram'){
        return(<InstagramCard  title={props.postData.title}
                               url={props.postData.uri}
                               message={props.postData.message}
                               postDate={displayDate}
                               tagArray={tagArry}/>)
    }
    else if(props.postData.type === 'Podcast'){
        return(<PodcastCard  title={props.postData.title}
                             url={props.postData.uri}
                             message={props.postData.message}
                             postDate={displayDate}
                             tagArray={tagArry}/>)
    }
    else if(props.postData.type === 'Article'){
        return(<ArticleCard  title={props.postData.title}
                             message={props.postData.message}
                             postDate={displayDate}
                             tagArray={tagArry}/>)
    }
    return(
        <div className=""></div>
    );
}
export default PostCard;