import React from "react";

function ArticleCard(props){

    return(
        <div className="post-card  custom-center">
            <h3 className="w3-left-align sizeable-text">{props.title}
                <a className='w3-right sizeable-text'> {props.postDate}</a>
            </h3>

                <h5 className="w3-left-align"> {props.message}</h5>

                <div className='tags'>
                    {props.tagArray.map((tag) => <a >{tag}{" "} </a>)}
                </div>

        </div>
    );
}
export default ArticleCard;