import React from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { solid, regular, brands, icon } from '@fortawesome/fontawesome-svg-core/import.macro' // <-- import styles to be used


function Footer(){
    return(
       
        <footer className="footer w3-opacity w3-light-grey w3-xxlarge ">
            {/* <a href="https://twitter.com/bardsjournal">
                <FontAwesomeIcon icon={brands('facebook')} />
            </a>
            
            <a href="https://twitter.com/bardsjournal" className="w3-padding-large">
                <FontAwesomeIcon icon={brands('twitter')} />
            </a> */}
            <a href="https://www.instagram.com/howsyourarmor/" >
                <FontAwesomeIcon icon={brands('instagram')} />
            </a>

        </footer>

    );
}
export default Footer;