import React, {useEffect} from 'react';



function InstagramCard(props){

    useEffect(() => {
        const onPageLoad = () => {
            {window.instgrm.Embeds.process()}
        };
    
        // Check if the page has already loaded
        if (document.readyState === 'complete') {
          onPageLoad();
        } else {
          window.addEventListener('load', onPageLoad);
          // Remove the event listener when component unmounts
          return () => window.removeEventListener('load', onPageLoad);
        }
      }, []);

    return(
        <div className="post-card custom-center">
            <h3 className="w3-left-align sizeable-text">{props.title}
                <a className='w3-right sizeable-text'> {props.postDate}</a>
            </h3>

            <div className="w3-centered">
                <center>
                    <div className="" dangerouslySetInnerHTML={{__html: props.url}}/>
                    
                </center>

            </div>
            <h5 className="w3-left-align"> {props.message}</h5>

            <div className='tags'>
                {props.tagArray && props.tagArray.map((tag) => <a >{tag}{" "} </a>)}
            </div>

        </div>
    );
}
export default InstagramCard;

