import React from 'react';
import NavBar from './NavBar.js';
import Footer from './Footer'

function Search(){
    return(
        <div>
             <NavBar/>
            <div className="w3-container">
                <div className="w3-center">
                    Still In Development
                </div>

            </div>

            <Footer/>
        </div>
    );
}
export default Search;