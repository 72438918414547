import React from 'react';


function VideoCard(props){
console.log('url is: ' + props.url);

    return(
        <div className="post-card custom-center w3-block">
            <h3 className="w3-left-align sizeable-text">{props.title}
                <a className='w3-right sizeable-text'> {props.postDate}</a>
            </h3>
            <center>
            <iframe className="video-comp"  src={props.url}
                        title="YouTube video player" frameBorder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen></iframe>
            </center>
            <h5 className="w3-left-align"> {props.message}</h5>

            <div className='tags'>
                {props.tagArray && props.tagArray.map((tag) => <a >{tag}{" "} </a>)}
            </div>

        </div>
    );
}
export default VideoCard;