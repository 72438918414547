import React from 'react';
import ReactDOM from 'react-dom/client';
import {BrowserRouter as Router, Routes, Route} from "react-router-dom";
// import Site from './components/Site';
import './resources/index.css';
import HomeFeed from './components/HomeFeed.js';
import Questions from './components/Questions.js';
import Search from './components/Search.js';
import AddPost from "./components/AddPost";


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
     <Router>
        <Routes>
          <Route path="/" element = {<HomeFeed/>} />
          <Route path="Search" element = {<Search/>} />
          <Route path="Questions" element = {<Questions/>} />
          <Route path="AddPost" element = {<AddPost/>} />
          <Route path="*" element = {<HomeFeed/>} />
        </Routes>
    </Router>
  </React.StrictMode>
);


